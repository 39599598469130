import React from "react"
const { Component } = React;

const Scrollbutton = () => {

const boxscroll = () => {
    if (typeof window !== `undefined`) {
      var element = document.getElementById("containerbox").style;
      var button = document.getElementById("button");
      if( element.overflow == "hidden" ) {
        element.overflow = "auto";
        button.innerHTML = "Disable Scroll";
      } else {
        element.overflow = "hidden";
        button.innerHTML = "Enable Scroll";

      }
    }
}
return (
    <button className="button is-info stickybutton" id="button" onClick={boxscroll}>Enable Scroll</button>
)

}

export default Scrollbutton