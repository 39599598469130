import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Hero from '../components/Hero'
import Img from "gatsby-image"
import logo_jandJ from "../images/Logo_jandj.png"
import logo_medtronic from "../images/Logo_medtronic.svg"
import logo_aws from "../images/Logo_aws.png"
import logo_microsoft from "../images/Logo_microsoft.svg"
import logo_amorphic from "../images/Logo_amorphic.svg"
import logo_mirrorweb from "../images/Logo_mirrorweb.svg"
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import BackToTopButton from '../components/BackToTopButton'
import { FaMicroscope, FaBook, FaDesktop, FaComments} from 'react-icons/fa'
import Scrollbutton from '../components/scrollbutton'
import Feed from '../components/feed'
import Loadable from "@loadable/component";

const RssFeed = Loadable(() => import('../components/feed_test'))

interface IndexPageProps {
  data: {
    hero
    webinar1
    site: {
      siteMetadata: {
        name: string
        tagline: string
      }
    }
  }
}

export const query = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        name
        tagline
      }
    }
    hero: file(relativePath: { eq: "hero_1.jpg" }) {
      childImageSharp {
        fluid(maxWidth:1600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    webinar: file(relativePath: { eq: "webinar_current.jpg" }) {
      childImageSharp {
        fluid(maxWidth:600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default class IndexPage extends React.Component<IndexPageProps, {}> {
  public render() {
    return (
      <>

      <GatsbySeo title='PanSurg Collaborative'
      description='PanSurg is a global initiative to deliver surgical education and research to the frontline during the COVID-19 pandemic.' />

      <Hero title="Research and education to fight COVID-19"
            subtitle="Collaboration, research, data and discussion to optimise the frontline
            delivery of surgical care during COVID-19"
            fluid={this.props.data.hero.childImageSharp.fluid}
      />

      <section className="section is-small" style={{marginTop:'0rem'}}>
        <div className="container">
          <div className="content">
            <p className="title has-text-darkBlue is-spaced">PanSurg is a global initiative to deliver education
            and research to the frontline during the COVID-19 pandemic.</p>
              </div>
              <div className="columns">
                <div className="column has-text-justified">
              <p className="title has-text-darkBlue is-spaced has-text-red">Our mission</p>
              <p><strong>PanSurg</strong> was created in recognition of the unparalleled challenges currently
              facing healthcare systems around the world from the COVID-19 pandemic.
              <strong> PanSurg</strong> is a global hub for surgeons and related professionals
              to share experiences, policy, data and research for the delivery of safe,
              effective surgery during the pandemic. <strong>We aim to make a difference to
              frontline surgical care in real-time.</strong></p>
              </div>
              <div className="column">
              <iframe src="https://www.youtube.com/embed/9Ta-kt2tpa0" style={{width: "100%", height: "100%"}}></iframe>
                </div>
              </div>
            </div>
          </section>

          <hr className="is-divider"/>

          <section className="section is-small" >
            <div className="container">
              <div className="">
                <div className="columns">

                  <div className="column">
                  <div className="box has-background-white-bis" style={{padding:20, position:'relative', overflow:"hidden", maxHeight:500}}>
                    <RssFeed />
                    <button className="button is-info" style={{position:"absolute", bottom:20, left:20}}><a href="/feed" className="has-text-white">See More</a></button>
                    </div>
                  </div>
                  <div className="column">
                    <div className="box" id="containerbox" style={{padding:20, maxHeight:500, overflow:'hidden', position:"relative"}}>
                    <a className="twitter-timeline" href="https://twitter.com/PanSurg?ref_src=twsrc%5Etfw">Tweets by PanSurg</a>
                    <Scrollbutton />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>

          <section className="section is-small" >
            <div className="container">
              <div className="">

                <div className="tile is-ancestor">

                  <div className="tile is-parent">

                    <Link to="/webinars" className="tile is-child box has-background-white-ter bounce">
                      <div style={{display: 'flex', alignItems:'center'}}>
                        <div className="index-icon icon is-medium">
                          <FaDesktop  className="is-size-3"/>
                        </div>
                        &nbsp;
                        <p className="title is-size-4 has-text-red">Webinars</p>
                      </div>
                      <br />
                      <div className="content">
                        <p className="subtitle is-size-6">Collaboration is our focus and we'll be
                        holding regular live webinars from surgeons and other stakeholders who are managing
                        surgical patients during the COVID-19 pandemic.
                        </p>

                        <p className="has-text-weight-bold">The last webinar was on Friday 2nd October.
                        Aftershock: Digital Health after COVID-19, a Live Peer Review.</p>
                      </div>

                      <div style={{maxWidth:'300px', margin: 'auto'}}>
                        <Img fluid={this.props.data.webinar.childImageSharp.fluid} alt="webinar" />
                      </div>
                    </Link>

                  </div>

                  <div className="tile is-parent is-vertical">

                    <Link to="/research" className="tile is-child box has-background-white-ter bounce">
                      <div style={{display: 'flex', alignItems:'center'}}>
                        <div className="index-icon icon is-medium">
                          <FaMicroscope  className="is-size-2"/>
                        </div>
                        &nbsp;
                        <p className="title is-size-4 has-text-red">Research</p>
                      </div>
                      <br/>
                        <div className="content">
                          <p className="subtitle is-size-6">The COVID-19 pandemic presents a completely novel threat.
                          Assimilating data and feeding back to the front-line is crucial. PanSurg is a hub for a variety
                          of surgically-orientated COVID-19 research themes.</p>
                        </div>
                    </Link>

                    <Link to="/resources" className="tile is-child box has-background-white-ter bounce">
                      <div style={{display: 'flex', alignItems:'center'}}>
                        <div className="index-icon icon is-medium">
                          <FaBook  className="is-size-2"/>
                        </div>
                        &nbsp;
                        <p className="title is-size-4 has-text-red">Resources</p>
                      </div>
                      <br/>
                      <div className="content">
                        <p className="subtitle is-size-6">A repository of policies and practices from colleagues
                        across the globe, describing how they're meeting the needs of their surgical patients,
                        their workforce and wider networks.</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
          </div>
        </section>

      <hr className="is-divider"/>

      <section className="section" style={{margin:'0rem 0rem'}} >
        <div className="container">
          <div className="is-thin">

            <p className="subtitle has-text-darkBlue">Industry Partner</p>

            <div className="logos">
              <a href=" https://www.jnjmedicaldevices.com/en-GB"><img width="400" src={logo_jandJ} alt="jnj_logo"/></a>
            </div>

            <p className="subtitle has-text-darkBlue">Industry Collaborators</p>

            <div className="logos">

              <a href="https://aws.amazon.com"><img width="100px" style={{marginRight:20}} src={logo_aws} alt="aws_logo"/></a>
              <a href="https://www.microsoft.com/en-gb"><img width="200px" src={logo_microsoft} alt="microsoft_logo"/></a>
              <a href="https://www.mirrorweb.com/"><img width="200px" src={logo_mirrorweb} alt="mirrorweb_logo"/></a>
              <a href="https://www.amorphicdata.com/"><img width="200px" src={logo_amorphic} alt="amorphicdata_logo"/></a>
            </div>
            <BackToTopButton />

          </div>
        </div>
      </section>
      </>
    )
  }
}
